<template>
    <div class="digital-earth h-100">
      <div class="news-warp">
        <van-popover
          v-model="isPop"
          theme="dark"
          trigger="click"
          :actions="newsTypeList"
          @select="getNews"
        >
          <template #reference>
            <van-button type="default" size="small">{{ newsType[news] }} <van-icon name="arrow-down" /></van-button>
          </template>
        </van-popover>
      </div>
      <NewsOverlay :overlay="overlay"  />
      <van-button type="info" class="config-button" to="/config/dataManage/point">系统后台</van-button>
      <Earth />
    </div>
  </template>
  <script>
  import Earth from './digitalEarth/components/Earth.vue'
  import NewsOverlay from './digitalEarth/components/NewsOverlay.vue'
  export default {
    components: { Earth, NewsOverlay },
    data() {
      return {
        isPop: false,
        overlay: {
          show: false,
          data: {}
        },
        news: 0,
        newsType: { 0: '新闻', 1: '动态' },
        newsTypeList: [
          { text: '新闻', value: 0 },
          { text: '动态', value: 1 },
        ]
      }
    },
    methods: {
      getNews(item) {
        this.news = item.value
        Object.assign(this.overlay, { show: true, data: { newsType: this.news }})
      }
    },
    mounted() {
    }
  
  }
  </script>
  
  <style lang="less" scoped>
  
  @import '../../style/color.less';
  .digital-earth {
    position: relative;
    .news-warp {
      position: absolute;
      top: 20px;
      left: 20px;
      color: @text-color;
      z-index: 1;
    }
    .config-button {
      position: absolute;
      top: 60px;
      left: 20px;
      color: @text-color;
      z-index: 1;
    }
      
  }
  
  </style>
  