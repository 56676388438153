<template>
  <van-overlay :show="overlay.show" @click="overlay.show = false">
    <div class="overlay-wrapper">
        <div class="overlay-content p-10" @click.stop>
            <van-list>
                <van-cell v-for="(item, index) in list" :key="index" :title="item" @click="goNews(item)"  />
            </van-list>
        </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
    props: {
        overlay: {
            type: Object,
        }
    },
    data() {
        return {
            list: [
                '新闻1',
                '新闻1',
                '新闻1',
                '新闻1',
                '新闻1',
            ]
        }
    },
    methods: {
        goNews(item) {
            console.log('goNews:', item)
        }
    }

}
</script>

<style lang="less" scoped>
.overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .overlay-content {
        width: 80%;
        height: 300px;
        border-radius: 5px;
        overflow-y: scroll;
        background-color: #fff;
    }
}

</style>